export enum StatusCode {
  Success = "ok",
  LoginFail = "CTE000",
  AlreadySignUpWithSocialLogin = "CTE001",
  AbnormalAccess = "CTE004",
  EmailNotExist = "CTE006",
  EmailVerificationFail = "CTE007",
  ValidationFail = "CTE008",
  UserNotFound = "CTE010",
  WrongPassword = "CTE011",
  Duplicate = "CTE013",

  // 구글 JWT
  TokenAuthFail = "BAE000",
  TokenExpired = "BAE001",

  UnknownFileError = "FPE002",
}

export const STATUS_CODE_ALERT_MESSAGE_MAP: {
  [key in StatusCode]?: { title?: string; description?: string };
} = {
  [StatusCode.LoginFail]: {
    title: "일치하는 정보가 없습니다.",
  },
  [StatusCode.AlreadySignUpWithSocialLogin]: {
    title: "SNS 계정으로 가입된 이메일입니다.",
  },
  [StatusCode.AbnormalAccess]: {
    title: "비정상적인 접근입니다.\n관리자에게 문의해주세요.",
  },
  [StatusCode.EmailNotExist]: {
    title: "등록되지 않은 이메일 주소입니다.",
    description: "회원 가입 후 굿뉴스RE100을 이용해주세요.",
  },
  [StatusCode.EmailVerificationFail]: {
    title: "이메일 검증에 실패했습니다.",
  },
  [StatusCode.ValidationFail]: {
    title: "전송값 검증에 실패했습니다.\n관리자에게 문의해주세요.",
  },
  [StatusCode.TokenAuthFail]: {
    title: "구글 인증에 실패했습니다.",
  },
  [StatusCode.TokenExpired]: {
    title: "구글 인증이 만료되었습니다.",
  },
};
