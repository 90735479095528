export const PAGE_ROUTES = {
  home: "/",
  // auth 관련
  signUp: "/auth/sign-up",
  googleSignUp: "/auth/google-sign-up",
  resetPasswordVerify: "/auth/reset-password-verify",
  resetPassword: "/auth/reset-password",
  resetPasswordSuccess: "/auth/reset-password-success",
  signUpComplete: "/auth/sign-up-complete",

  dashboard: {
    // RE100 이행
    re100Management: "/dashboard/re100-management",
    // 계약 관리
    contractManagement: "/dashboard/contract-management",
    contractManagementDetail: "/dashboard/contract-management/detail",
    // 전력 사용내역
    powerUsageHistory: "/dashboard/power-usage-history",
    powerUsageHistoryDetail: "/dashboard/power-usage-history/detail",
    // 재생에너지 구매
    buyingRenewableEnergy: "/dashboard/buying-renewable-energy",
    // 목표 설정
    goalSetting: "/dashboard/goal-setting",

    // 계정 설정
    accountSetting: "/dashboard/account-setting",
    // 스페이스 설정
    spaceSetting: "/dashboard/space-setting",
    // 스페이스 상세
    spaceDetail: "/dashboard/space-setting/detail",
  },
  popup: {
    plantDetail: "/popup/plant-detail",
  },
  notFound: "/not-found",
};

export const PAGE_TITLE_MAP = {
  [PAGE_ROUTES.dashboard.accountSetting]: "계정 설정",
  [PAGE_ROUTES.dashboard.spaceSetting]: "스페이스 설정",
  [PAGE_ROUTES.dashboard.contractManagement]: "계약 관리",
  [PAGE_ROUTES.dashboard.re100Management]: "RE100 이행",
  [PAGE_ROUTES.dashboard.powerUsageHistory]: "전력 사용내역",
  [PAGE_ROUTES.dashboard.buyingRenewableEnergy]: "재생에너지 구매",
};

export enum PowerUsageHistoryTabType {
  UsageHistory = "usageHistory",
  PowerUsagePlaceManagement = "powerUsagePlaceManagement",
}

export const PAGE_TAB_MAP = {
  [PAGE_ROUTES.dashboard.powerUsageHistory]: {
    defaultTab: PowerUsageHistoryTabType.UsageHistory,
    tabItemList: [
      { tabType: PowerUsageHistoryTabType.UsageHistory, tabName: "사용내역" },
      {
        tabType: PowerUsageHistoryTabType.PowerUsagePlaceManagement,
        tabName: "전기 사용장소 관리",
      },
    ],
  },
} satisfies {
  [key in keyof typeof PAGE_ROUTES]?: {
    defaultTab: PowerUsageHistoryTabType;
    tabItemList: { tabType: PowerUsageHistoryTabType; tabName: string }[];
  };
};
