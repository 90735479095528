/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * 월간햇빛바람 수요자 API
 * OpenAPI spec version: develop
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  BodyV1UserImagePut,
  ChangePasswordRequest,
  DeleteV1UserImage200,
  EmailAuthRequest,
  FindPasswordRequest,
  GetV1User200,
  GetV1UserImage200,
  LoginRequest,
  PostV1UserFindPasswordAuth200,
  PostV1UserFindPasswordAuthVerify200,
  PostV1UserLogin200,
  PostV1UserLogout200,
  PutV1User200,
  PutV1UserFindPassword200,
  PutV1UserImage200,
  PutV1UserPassword200,
  UpdateUserInfoRequest,
  VerifyEmailRequest,
} from "../connect-api.schemas";

/**
 * @summary 일반 로그인
 */
export const postV1UserLogin = (
  loginRequest: LoginRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserLogin200>> => {
  return axios.post(`/consumer/v1/user/login`, loginRequest, options);
};

export const getPostV1UserLoginMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogin>>, TError, { data: LoginRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogin>>, TError, { data: LoginRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postV1UserLogin>>, { data: LoginRequest }> = (props) => {
    const { data } = props ?? {};

    return postV1UserLogin(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postV1UserLogin>>>;
export type PostV1UserLoginMutationBody = LoginRequest;
export type PostV1UserLoginMutationError = AxiosError<unknown>;

/**
 * @summary 일반 로그인
 */
export const usePostV1UserLogin = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogin>>, TError, { data: LoginRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postV1UserLogin>>, TError, { data: LoginRequest }, TContext> => {
  const mutationOptions = getPostV1UserLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 로그인 확인
 */
export const getV1UserCheckLogin = (options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> => {
  return axios.get(`/consumer/v1/user/check-login`, options);
};

export const getGetV1UserCheckLoginQueryKey = () => {
  return [`/consumer/v1/user/check-login`] as const;
};

export const getGetV1UserCheckLoginQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1UserCheckLogin>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1UserCheckLogin>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserCheckLoginQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1UserCheckLogin>>> = ({ signal }) =>
    getV1UserCheckLogin({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1UserCheckLogin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserCheckLoginQueryResult = NonNullable<Awaited<ReturnType<typeof getV1UserCheckLogin>>>;
export type GetV1UserCheckLoginQueryError = AxiosError<unknown>;

/**
 * @summary 로그인 확인
 */
export const useGetV1UserCheckLogin = <
  TData = Awaited<ReturnType<typeof getV1UserCheckLogin>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1UserCheckLogin>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserCheckLoginQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 로그아웃
 */
export const postV1UserLogout = (options?: AxiosRequestConfig): Promise<AxiosResponse<PostV1UserLogout200>> => {
  return axios.post(`/consumer/v1/user/logout`, undefined, options);
};

export const getPostV1UserLogoutMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogout>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogout>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postV1UserLogout>>, void> = () => {
    return postV1UserLogout(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof postV1UserLogout>>>;

export type PostV1UserLogoutMutationError = AxiosError<unknown>;

/**
 * @summary 로그아웃
 */
export const usePostV1UserLogout = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postV1UserLogout>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postV1UserLogout>>, TError, void, TContext> => {
  const mutationOptions = getPostV1UserLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 발송(비밀번호 찾기용)
 */
export const postV1UserFindPasswordAuth = (
  emailAuthRequest: EmailAuthRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserFindPasswordAuth200>> => {
  return axios.post(`/consumer/v1/user/find-password-auth`, emailAuthRequest, options);
};

export const getPostV1UserFindPasswordAuthMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    { data: EmailAuthRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserFindPasswordAuth(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserFindPasswordAuthMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>
>;
export type PostV1UserFindPasswordAuthMutationBody = EmailAuthRequest;
export type PostV1UserFindPasswordAuthMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 발송(비밀번호 찾기용)
 */
export const usePostV1UserFindPasswordAuth = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserFindPasswordAuthMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 확인(비밀번호 찾기용)
 */
export const postV1UserFindPasswordAuthVerify = (
  verifyEmailRequest: VerifyEmailRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserFindPasswordAuthVerify200>> => {
  return axios.post(`/consumer/v1/user/find-password-auth/verify`, verifyEmailRequest, options);
};

export const getPostV1UserFindPasswordAuthVerifyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    { data: VerifyEmailRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserFindPasswordAuthVerify(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserFindPasswordAuthVerifyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>
>;
export type PostV1UserFindPasswordAuthVerifyMutationBody = VerifyEmailRequest;
export type PostV1UserFindPasswordAuthVerifyMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 확인(비밀번호 찾기용)
 */
export const usePostV1UserFindPasswordAuthVerify = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserFindPasswordAuthVerifyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 찾기
 */
export const putV1UserFindPassword = (
  findPasswordRequest: FindPasswordRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserFindPassword200>> => {
  return axios.put(`/consumer/v1/user/find-password`, findPasswordRequest, options);
};

export const getPutV1UserFindPasswordMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    TError,
    { data: FindPasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1UserFindPassword>>,
  TError,
  { data: FindPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    { data: FindPasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return putV1UserFindPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserFindPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof putV1UserFindPassword>>>;
export type PutV1UserFindPasswordMutationBody = FindPasswordRequest;
export type PutV1UserFindPasswordMutationError = AxiosError<unknown>;

/**
 * @summary 비밀번호 찾기
 */
export const usePutV1UserFindPassword = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    TError,
    { data: FindPasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1UserFindPassword>>,
  TError,
  { data: FindPasswordRequest },
  TContext
> => {
  const mutationOptions = getPutV1UserFindPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 변경
 */
export const putV1UserPassword = (
  changePasswordRequest: ChangePasswordRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserPassword200>> => {
  return axios.put(`/consumer/v1/user/password`, changePasswordRequest, options);
};

export const getPutV1UserPasswordMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserPassword>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1UserPassword>>,
  TError,
  { data: ChangePasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putV1UserPassword>>, { data: ChangePasswordRequest }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return putV1UserPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof putV1UserPassword>>>;
export type PutV1UserPasswordMutationBody = ChangePasswordRequest;
export type PutV1UserPasswordMutationError = AxiosError<unknown>;

/**
 * @summary 비밀번호 변경
 */
export const usePutV1UserPassword = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserPassword>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1UserPassword>>,
  TError,
  { data: ChangePasswordRequest },
  TContext
> => {
  const mutationOptions = getPutV1UserPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 정보 조회
 */
export const getV1User = (options?: AxiosRequestConfig): Promise<AxiosResponse<GetV1User200>> => {
  return axios.get(`/consumer/v1/user`, options);
};

export const getGetV1UserQueryKey = () => {
  return [`/consumer/v1/user`] as const;
};

export const getGetV1UserQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1User>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1User>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1User>>> = ({ signal }) =>
    getV1User({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1User>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserQueryResult = NonNullable<Awaited<ReturnType<typeof getV1User>>>;
export type GetV1UserQueryError = AxiosError<unknown>;

/**
 * @summary 회원 정보 조회
 */
export const useGetV1User = <TData = Awaited<ReturnType<typeof getV1User>>, TError = AxiosError<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1User>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 회원 정보 수정
 */
export const putV1User = (
  updateUserInfoRequest: UpdateUserInfoRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1User200>> => {
  return axios.put(`/consumer/v1/user`, updateUserInfoRequest, options);
};

export const getPutV1UserMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1User>>,
    TError,
    { data: UpdateUserInfoRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putV1User>>, TError, { data: UpdateUserInfoRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putV1User>>, { data: UpdateUserInfoRequest }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return putV1User(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserMutationResult = NonNullable<Awaited<ReturnType<typeof putV1User>>>;
export type PutV1UserMutationBody = UpdateUserInfoRequest;
export type PutV1UserMutationError = AxiosError<unknown>;

/**
 * @summary 회원 정보 수정
 */
export const usePutV1User = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1User>>,
    TError,
    { data: UpdateUserInfoRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putV1User>>, TError, { data: UpdateUserInfoRequest }, TContext> => {
  const mutationOptions = getPutV1UserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 이미지 조회
 */
export const getV1UserImage = (options?: AxiosRequestConfig): Promise<AxiosResponse<GetV1UserImage200>> => {
  return axios.get(`/consumer/v1/user/image`, options);
};

export const getGetV1UserImageQueryKey = () => {
  return [`/consumer/v1/user/image`] as const;
};

export const getGetV1UserImageQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1UserImage>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1UserImage>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserImageQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1UserImage>>> = ({ signal }) =>
    getV1UserImage({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1UserImage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserImageQueryResult = NonNullable<Awaited<ReturnType<typeof getV1UserImage>>>;
export type GetV1UserImageQueryError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 조회
 */
export const useGetV1UserImage = <
  TData = Awaited<ReturnType<typeof getV1UserImage>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getV1UserImage>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserImageQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 회원 이미지 수정
 */
export const putV1UserImage = (
  bodyV1UserImagePut: BodyV1UserImagePut,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserImage200>> => {
  const formData = new FormData();
  formData.append("user_image", bodyV1UserImagePut.user_image);

  return axios.put(`/consumer/v1/user/image`, formData, options);
};

export const getPutV1UserImageMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserImage>>,
    TError,
    { data: BodyV1UserImagePut },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putV1UserImage>>, TError, { data: BodyV1UserImagePut }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putV1UserImage>>, { data: BodyV1UserImagePut }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return putV1UserImage(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserImageMutationResult = NonNullable<Awaited<ReturnType<typeof putV1UserImage>>>;
export type PutV1UserImageMutationBody = BodyV1UserImagePut;
export type PutV1UserImageMutationError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 수정
 */
export const usePutV1UserImage = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserImage>>,
    TError,
    { data: BodyV1UserImagePut },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putV1UserImage>>, TError, { data: BodyV1UserImagePut }, TContext> => {
  const mutationOptions = getPutV1UserImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 이미지 삭제
 */
export const deleteV1UserImage = (options?: AxiosRequestConfig): Promise<AxiosResponse<DeleteV1UserImage200>> => {
  return axios.delete(`/consumer/v1/user/image`, options);
};

export const getDeleteV1UserImageMutationOptions = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteV1UserImage>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteV1UserImage>>, TError, void, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteV1UserImage>>, void> = () => {
    return deleteV1UserImage(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteV1UserImageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteV1UserImage>>>;

export type DeleteV1UserImageMutationError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 삭제
 */
export const useDeleteV1UserImage = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteV1UserImage>>, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteV1UserImage>>, TError, void, TContext> => {
  const mutationOptions = getDeleteV1UserImageMutationOptions(options);

  return useMutation(mutationOptions);
};
